import React from "react";
import Img from "gatsby-image";
import { graphql, StaticQuery, navigate } from "gatsby";
import styled from "styled-components";
import BackgroundImage from "gatsby-background-image";
import ReactPlayer from "react-player";
import { Box } from "reflexbox";

import { styleBackgroundImage } from "../../components/Section/section.style";
import { Row, Col } from "react-styled-flexboxgrid";

import { Primary, Alternate } from "../../components/Section";
import { ButtonGhost } from "../../components/Button";

const BackgroundSection = ({ className, children }) => (
  <StaticQuery
    query={graphql`
      query {
        desktop: file(relativePath: { eq: "hero-csm.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={(data) => {
      // Set ImageData.
      const imageData = data.desktop.childImageSharp.fluid;
      return (
        <BackgroundImage
          Tag="section"
          className={className}
          fluid={imageData}
          backgroundColor={`#040e18`}
        >
          {children}
        </BackgroundImage>
      );
    }}
  />
);

const StyledBackgroundSection = styled(BackgroundSection)`
  ${styleBackgroundImage};
  background-position-y: 35%;
`;

const Agency = ({ data }) => (
  <section>
    <StyledBackgroundSection centered height={350}>
      <Row>
        <Col xs={12}>
          {/* <h1>Sports Agency</h1>
          <h2>Professional. Knowledgeable. Relatable.</h2> */}
        </Col>
      </Row>
    </StyledBackgroundSection>
    <Primary centered>
      <Row>
        <Col xs={12}>
          {/* <StyledLogoMediation
            src={LogoCsm}
            alt="condition sports management logo"
          /> */}
          <h2>About Condition Sports Management</h2>
          <p>
            Condition Sports Management was founded by Michael S. Joyner, MD.
            Dr. Joyner is a hand surgeon, author of Forever My Daddy: Denied,
            and founder of the Forever My Daddy Grand Foundation. Dr. Michael
            J’s experience helping people with asset protection, personal
            coaching, and mediation, was the driving force for him to create
            Condition Sports Management. CSM is a new agency that is dedicated
            to helping young athletes progress through their career from high
            school, college and to the professional level and even post-career
            transition. CSM is here to help its clients maximize their earning
            potential at every stage.
          </p>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <Box sx={{ display: `flex`, justifyContent: `center` }}>
            <ReactPlayer url="http://youtu.be/zMY_v1PGsHI?hd=1" />
          </Box>
        </Col>
      </Row>
    </Primary>

    <Alternate>
      <Row>
        <Col xs={12} md={7}>
          <h2>CSM & Historically Black Colleges and Universities</h2>
          <p>
            Condition Sports Management not only pays attention to "Power 5”
            schools but also is becoming the indispensable cornerstone to future
            athlete’s success helping those who attend historically black
            colleges and/or universities that aspire to make it to the next
            level but are overlooked.
          </p>
          <h4>Professional. Knowledgeable. Relatable.</h4>
          <p>
            In order to become an elite athlete, one must be driven, determined,
            ambitious, with a serious <i>“conditioning”</i> program along with
            an <i>“unconditional”</i> drive to reach their goals. We look
            forward to working with students who are driven and motivated to
            become an elite athlete.
          </p>
          <ButtonGhost onClick={() => navigate("/reach-us")}>
            Contact Us
          </ButtonGhost>
        </Col>
        <Col xs={12} md={5}>
          <Img
            fluid={data.logocsm.childImageSharp.fluid}
            alt="logo condition sports management"
          />
        </Col>
      </Row>
    </Alternate>
  </section>
);

export default Agency;
